html {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}
body {
    overflow-y: visible;
    position: relative;
    overflow-x: hidden;
}
html,
body {
    margin: 0;
}

.container1 {
    width: 1100vw!important;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.firstContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /*background: yellow;*/
}

.lastContainer {
    /*display: flex;*/
    height: 100vh;
    /*background: yellow;*/
}

.panel {
    width: 100vw;
    height: 100vh;
}
.red {
    background: blue;
}
.red {
    background: red;
}
.orange {
    background: orange;
}
.purple {
    background: purple;
}
.blue {
    background-color: blue;
}

/*
    BG
*/

.bgCustom {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}