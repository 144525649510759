html,
body {
  margin: 0;
  font-family: sans-serif;
}

.app-info-section small {
  font-size: 28px!important;
}

.h-div {
  overflow-x: hidden;
  display: flex;
}


section {
  min-width: 50vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4ch;
}

.img-wrapper {
  width: 640px;
  height: 320px;
}
.skew {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  .scroll {
    padding: 2vh 0;
    pointer-events: none;
  }
  .img-container {
    width: 700px;
    height: 420px;
    overflow: hidden;
    position: relative;
    margin: 400px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      margin-top: 200px;
    }
    &:last-child {
      margin-bottom: 200px;
    }
    @media (max-width: 480px) {
      width: 95%;
    }
    img {
      width: 100%;
      position: absolute;
    }
  }
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction='horizontal'] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction='horizontal'] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction='horizontal'] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction='horizontal'] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction='horizontal'] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}
