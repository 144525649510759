
.footer-section, .social-media-section {
    padding-top: 20px;
    background: #e3e3e3;
}

.bg-bottom {
    background: #e3e3e3
}

/* Google font */
@import url('https://fonts.googleapis.com/css?family=Montserrat');

.sections {
    height: auto;
    background: linear-gradient(#f8f8f8, #dddddd);
}

* {
    font-family: 'Montserrat', sans-serif;
}

.box {
    transition: box-shadow .3s;
    border: 1px solid #ccc;
    background: #fff;
    float: left;

}
.box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
}

#m img{
    height: 100vh;
    object-fit: contain!important;
}

.phone {
    -webkit-animation: action 1s infinite  alternate;
    animation: action 1s infinite  alternate;
}

@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}


/** LIGHTBOX MARKUP **/

.lightbox {
    /* Default to hidden */
    display: none;

    /* Overlay entire screen */
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* A bit of padding around image */
    padding: 1em;

    /* Translucent background */
    background: rgba(0, 0, 0, 0.8);
}

/* Unhide the lightbox when it's the target */
.lightbox:target {
    display: block;
}

.lightbox span {
    /* Full width and height */
    display: block;
    width: 100%;
    height: 100%;

    /* Size and position background image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}