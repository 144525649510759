.background {
    background: linear-gradient(#e66465, #9198e5);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.scrollText {
    font-size: 1.5rem;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    height: 10rem;
    width: 25%;
    text-align: center;
    border-radius: 10px;
}

.parallax {
    margin-right: 15%;
}

.sticky {
    margin-left: 15%;
    background-color: #ff6d6d;
}

.blue {
    background-color: #5b65ad;
    color: white;
}

.purple {
    background-color: #9d65d0;
}

@media (max-width: 750px) {
    .card {
        width: 40%;
    }

    .parallax {
        margin-right: 1.5rem;
    }

    .sticky {
        margin-left: 1.5rem;
    }
}

.bgCustom {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
